<template>
  <div class="event-single" v-show="!isLoading">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">{{ event.name }}</h1>
          <h2 class="subtitle">
            <div class="date" v-if="event.date">
              <strong>Date:</strong>
              {{ event.date }}
            </div>
            <br />
            <div class="time" v-if="event.time">
              <strong>Time:</strong>
              {{ event.time }}
            </div>
          </h2>
        </div>
      </div>
    </section>
    <section class="event-content">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="event-images columns is-multiline has-text-centered">
              <img :src="event.featuredImage" alt="Placeholder image" id="eventImage" />
            </div>
          </div>
          <div class="column">
            <p class="is-size-5 description">{{ event.description }}</p>
            <p class="is-size-5" v-if="event.location">
              <strong>Location:</strong>
              {{ event.location }}
            </p>
            <div class="socialMedia">
              <a target="_blank" rel="noopener noreferrer" :href="event.facebookLink" v-if="event.facebookLink">
                <img
                  src="../img/fb.png"
                />
              </a>
              <a target="_blank" rel="noopener noreferrer" :href="event.websiteLink" v-if="event.websiteLink">
                <img src="../img/web.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import EventService from "../services/eventService"

export default {
  name: "EventSingle",
  data() {
    // initialize the event object
    return {
      isLoading: false,
      event: {}
    };
  },
  // async firestore() {
  //   await db
  //     .collection("events")
  //     .doc(this.$route.params.id)
  //     .get()
  //     .then(doc => {
  //       this.$set(this, "event", _.assign(doc.data(), { id: doc.id }));
  //     });
  // }
  async mounted(){
    this.event = await EventService.get(this.$route.params.id);
  }
};
</script>
<style scoped>
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-bottom: 40px;
}

.hero.is-primary {
  background-color: transparent;
}
#eventImage {
  max-height: 450px;
}
.socialMedia a img {
  max-height: 40px;
  margin: 5px;
}
</style>